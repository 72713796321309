import { Component, Injectable, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleInjectorService } from '../../core/moduleinjector.service';
import { EmpusaMicroApp, User, Site, AuthenticationService, EmpusaMicroAppModule } from '@empusa/empusa-core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from 'src/app/translations/paginator.i18n';
import { PhotoService } from 'src/app/client/photo.service';
import { arrayBufferToBase64 } from 'src/app/core/util';
import { ProfileUserComponent } from '../profile/profile.page';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentTermsDialog } from './terms/dialog-content-terms-dialog';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.css'],
    providers: [ModuleInjectorService]
})
export class HomePage {

    /** username logged in */
    user: User;
    username: String
    pictureURL: String

    /** selected site */
    site: Site;

    /** list of modules availables */
    modules: EmpusaMicroApp[] = [];

    /** list of available sites to be selected */
    sites: Site[];

    /** the current page selected */
    currentDynamicPage: string = "";

    /** the current selected lang */
    currentLang: string = "";

    currentPath: string = "";
    isAdmin: boolean;
    selectedSiteControl = new FormControl();
    bmsGrafana: string;
    incluideGrafana: boolean = false;
    isMori: boolean = false;

    @ViewChild('userPicture') userPictureImg;

    @ViewChild('dynamic', {
        read: ViewContainerRef
    }) viewContainerRef: ViewContainerRef

    constructor(private apppinjector: ModuleInjectorService,
        private router: Router, private matPaginator: MatPaginatorIntl,
        private dialog: MatDialog,
        private paginator: PaginatorI18n,
        private authService: AuthenticationService,
        private photoService: PhotoService,
        iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
        private translate: TranslateService) {

        this.currentLang = translate.currentLang;
        iconRegistry.addSvgIcon(
            'flag-es',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/es.svg'));
        iconRegistry.addSvgIcon(
            'flag-en',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/en.svg'));

        this.currentPath = this.getCurrentPath();
        this.bmsGrafana = environment.grafana;
        if( !environment.billing_integration_enabled){
            this.isMori = true;
        }
        if(this.bmsGrafana != ''){
          this.incluideGrafana = true
        }
        this.authService.loadCurrentUser().then((user: User) => {
            this.user = user;
            this.isAdmin = this.authService.hasCurrentUserRole('ADMIN');
            this.photoService.getPhoto(user).subscribe(data => {
                this.userPictureImg.nativeElement.src = 'data:image/jpeg;base64,' + arrayBufferToBase64(data);
            },
                error => {
                    //nothing, it will get the default image
                });

            this.sites = user.sites;
            //the first site is selected by default
            this.site = this.sites[0];
            this.selectedSiteControl.setValue(this.site.uid);
            this.authService.setCurrentSite(this.site);

            this.router.navigate(['/']);
            let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;
            if (allModules && allModules != null) {
                allModules.forEach(module => {
                    //******************QUITADO LA AUTORIZACIONES */
                    //this.modules.push(module);

                    this.authService.loadMicroappPermissions(module.name).then(() => {
                        this.authService.canAccessMicroapp(module.name).then(canAccess => {
                            if (canAccess) {
                                if (this.modules && this.modules.length > 0 && module.path === this.currentPath) {
                                    setTimeout(() => {
                                        this.router.navigate([this.currentPath])
                                    }, 0);
                                } else if (this.modules && module.homeApp === true && this.currentPath === '') {
                                    setTimeout(() => {
                                        this.router.navigate([module.path]);
                                    }, 0);
                                    
                                }

                                this.modules.push(module);
                                // this.modules.sort((a, b) =>
                                //     a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
                                //         a.name.toLowerCase() < b.name.toLowerCase() ? -1 :
                                //             0
                                // );
                                this.modules.sort(function (a, b) {
                                    return (a.order - b.order)
                                })
                            }
                        })
                    });
                });
            }


        });

    }

    getCurrentPath(): string {
        let currentRoute = this.router.url;
        return currentRoute.split('/')[1];
    }

    onEmpusaAppClick(name, page) {
        if (this.currentDynamicPage !== name) {
            this.currentDynamicPage = name;
            this.apppinjector.setRootViewContainerRef(this.viewContainerRef)
            this.apppinjector.addDynamicComponent(page);
        }
    }

    onSiteChange(event) {
        this.site = this.user.sites.find(site => site.uid == event.value);
        this.authService.setCurrentSite(this.site);
    }

    onChangeLanguage(lang: string) {
        this.translate.use(lang).toPromise().then(value => {
            this.paginator.refresh(this.matPaginator);
            this.matPaginator.changes.next();
        });
        this.currentLang = lang;
    }

    logout() {
        this.router.navigateByUrl('/');
        setTimeout(() => {
            this.authService.logout();
        }, 0);
        
    }

    profileUser() {
        let ticket_id = this.user.uid

        let dialogAssign = this.dialog.open(ProfileUserComponent, {
            width: '680px',
            data: ticket_id,
        });
        dialogAssign.afterClosed().subscribe(result => {
          if(result){
            this.user = result
          } 
        });
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogContentTermsDialog);
    
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }
 }

