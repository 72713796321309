import { NgModule } from '@angular/core';
import { MicroappHomeModule } from 'microapp-home';
import { MicroappUsermanagementModule } from 'microapp-usermanagement';
import { MicroappVmsModule } from 'microapp-vms';

@NgModule({
  imports: [
  MicroappHomeModule,MicroappUsermanagementModule,MicroappVmsModule,
  ],
  exports: [], providers:[]
})
export class ExternalEmpusaModule { }
