export const environment = {
  production: true,
  urlbase: '/',
  auth: 'keycloak',
  backend: 'real',
  grafana: 'https://grafana.getronettes.getronicssmartspaces.com/grafana',
  keycloak: {
    'url': 'https://getronettes.getronicssmartspaces.com/auth',
    'realm': 'Boustead',
    'clientId': 'empusa',
  },
  //the currency used
  currency: 'S$',
  show_acess_group: true,
  billing_integration_enabled: false
};
